import { useAuth0 } from '@auth0/auth0-react'

const ROLES_URL = 'http://belfry.roles.com/roles'
const BELFRY_INTERNAL_ORG = ['org_7Q6oD3pzP6GL2LyR', 'org_chAXcStjxla3RcLJ']

const prodAdminRoleId = 'rol_7c58cr2QK5YbCaXF'
const prodManagerRoleId = 'rol_t5Yx3BjRrHXGEbco'
const prodSupervisorRoleId = 'rol_LPpBI1VxO3Z46Abs'
const prodHRRoleId = 'rol_cDQ4VASa4jsSrby3'
const prodFinanceRoleId = 'rol_qasq6IXgTJgE8anT'
const prodOfficerRoleId = 'rol_ItZALN1F0rIHaUfO'

const devAdminRoleId = 'rol_ZvW8UI0UkYc1qCAB'
const devManagerRoleId = 'rol_q9GlB2TbrwwFKeXA'
const devSupervisorRoleId = 'rol_jfpXgV4CJSo7b0GR'
const devHRRoleId = 'rol_tieomIARFf21voZJ'
const devFinanceRoleId = 'rol_yFZBP859AT32Ay12'
const devOfficerRoleId = 'rol_mLzDDY8kCFK7eikB'

const dev = process.env.NODE_ENV !== 'production'

export const devRoleMap: Record<Role, string> = {
    Admin: devAdminRoleId,
    Manager: devManagerRoleId,
    Supervisor: devSupervisorRoleId,
    HR: devHRRoleId,
    Finance: devFinanceRoleId,
    Officer: devOfficerRoleId,
}

export const prodRoleMap: Record<Role, string> = {
    Admin: prodAdminRoleId,
    Manager: prodManagerRoleId,
    Supervisor: prodSupervisorRoleId,
    HR: prodHRRoleId,
    Finance: prodFinanceRoleId,
    Officer: prodOfficerRoleId,
}

export const roleMap = dev ? devRoleMap : prodRoleMap

export const OFFICER_ROLE_ID = dev ? devOfficerRoleId : prodOfficerRoleId

export const ADMIN_ROLE = 'Admin'
export const FINANCE_ROLE = 'Finance'
export const HR_ROLE = 'HR'
export const MANAGER_ROLE = 'Manager'
export const SUPERVISOR_ROLE = 'Supervisor'
export const OFFICER_ROLE = 'Officer'
export const CLIENT_ROLE = 'Client'

const roleRanks = [
    ADMIN_ROLE,
    FINANCE_ROLE,
    HR_ROLE,
    MANAGER_ROLE,
    SUPERVISOR_ROLE,
]
import { INTERNAL_USERS } from '../constants/previewEligibleOrgs'
import { useFirmVisitationState } from 'src/components/FirmSwitching/firmVisitationState'
import { BelfryRole } from 'src/generated'

export const CHAT_ROLES: Role[] = [
    ADMIN_ROLE,
    FINANCE_ROLE,
    HR_ROLE,
    MANAGER_ROLE,
    SUPERVISOR_ROLE,
    OFFICER_ROLE,
]
export const PAYROLL_ROLES: Role[] = [ADMIN_ROLE, FINANCE_ROLE, HR_ROLE]

export type BelfryNonClientRole =
    | typeof BelfryRole.ROLE_ADMIN
    | typeof BelfryRole.ROLE_FINANCE
    | typeof BelfryRole.ROLE_HR
    | typeof BelfryRole.ROLE_MANAGER
    | typeof BelfryRole.ROLE_SUPERVISOR
    | typeof BelfryRole.ROLE_OFFICER

export const belfryRoleToReadable: Record<BelfryNonClientRole, Role> = {
    [BelfryRole.ROLE_ADMIN]: ADMIN_ROLE,
    [BelfryRole.ROLE_FINANCE]: FINANCE_ROLE,
    [BelfryRole.ROLE_HR]: HR_ROLE,
    [BelfryRole.ROLE_MANAGER]: MANAGER_ROLE,
    [BelfryRole.ROLE_SUPERVISOR]: SUPERVISOR_ROLE,
    [BelfryRole.ROLE_OFFICER]: OFFICER_ROLE,
}

export type Role =
    | typeof ADMIN_ROLE
    | typeof FINANCE_ROLE
    | typeof HR_ROLE
    | typeof MANAGER_ROLE
    | typeof SUPERVISOR_ROLE
    | typeof OFFICER_ROLE

// Map of which roles are above which roles
// Answers the question: "what roles can't I view."
interface RoleHierarchy {
    [key: string]: string[]
    Admin: string[]
    HR: string[]
    Manager: string[]
    Supervisor: string[]
}

const roleHierarchy: RoleHierarchy = {
    Admin: [],
    Finance: dev
        ? [devAdminRoleId, devHRRoleId]
        : [prodAdminRoleId, prodHRRoleId],
    HR: dev
        ? [devAdminRoleId, devFinanceRoleId, devHRRoleId]
        : [prodAdminRoleId, prodFinanceRoleId, prodHRRoleId],
    Manager: dev
        ? [devAdminRoleId, devManagerRoleId, devHRRoleId]
        : [prodAdminRoleId, prodManagerRoleId, prodHRRoleId],
    Supervisor: dev
        ? [devAdminRoleId, devManagerRoleId, devSupervisorRoleId, devHRRoleId]
        : [
              prodAdminRoleId,
              prodManagerRoleId,
              prodSupervisorRoleId,
              prodHRRoleId,
          ],
}

export const useRolesOfUser = () => {
    const { user } = useAuth0()
    return user ? user[ROLES_URL] : undefined
}

export const useMaxRoleOfUser = () => {
    const { user } = useAuth0()
    const userRole = user ? user[ROLES_URL] : undefined
    return roleRanks.find((roleRank) => userRole.includes(roleRank))
}

export const useOrgOfUser = () => {
    const { user } = useAuth0()
    return user ? user['org_id'] : undefined
}

export const useInternalUser = () => {
    const orgId = useOrgOfUser()
    return orgId ? BELFRY_INTERNAL_ORG.includes(orgId) : false
}

export const useUserIsAdmin = () => {
    const { user } = useAuth0()

    return user ? user[ROLES_URL].includes('Admin') : false
}

export const useUserIsInternal = () => {
    const { user } = useAuth0()
    const { visitActive } = useFirmVisitationState()
    return (
        visitActive || !!(user && user.sub && INTERNAL_USERS.includes(user.sub))
    )
}

export const useUserIsPayrollPrivileged = () => {
    const { user } = useAuth0()

    return user
        ? user[ROLES_URL].includes('Admin') ||
              user[ROLES_URL].includes('HR') ||
              user[ROLES_URL].includes('Finance')
        : false
}

export const useUserIsBillingPrivileged = () => {
    const { user } = useAuth0()

    return user
        ? user[ROLES_URL].includes('Admin') ||
              user[ROLES_URL].includes('Finance')
        : false
}

export const useUserIsOfRole = (roleNames: Role[]): boolean => {
    const { user } = useAuth0()

    return user
        ? user[ROLES_URL].filter((role: Role) => roleNames.includes(role))
              .length > 0
        : false
}

export const useUserIsFinancePrivileged = () => {
    const { user } = useAuth0()

    return user
        ? user[ROLES_URL].includes('Admin') ||
              user[ROLES_URL].includes('Finance') ||
              user[ROLES_URL].includes('Manager') ||
              user[ROLES_URL].includes('HR')
        : false
}

export const useIsSubrole = (referenceRoleId: string | undefined): boolean => {
    const userRoles = useRolesOfUser()
    if (!userRoles) {
        return false
    }
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return (
        !!referenceRoleId &&
        !!maxRole &&
        roleHierarchy[maxRole].includes(referenceRoleId)
    )
}

export const useGetOverRoles = (): string[] => {
    const userRoles = useRolesOfUser()
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return maxRole && roleHierarchy[maxRole] ? roleHierarchy[maxRole] : []
}

/** Determines whether a provided role id is < the given role string */
export const roleIsOverReferenceRoleId = (
    role: string | undefined,
    referenceRoleId: string | undefined
) => {
    return (
        role === 'Admin' ||
        (!!referenceRoleId &&
            !!role &&
            !roleHierarchy[role].includes(referenceRoleId))
    )
}

// We use prod role ids even in dev. So when looking up a role by id we need to accept the either the dev version or the prod version
const roleIdsByName: Record<Role, Set<string>> = {
    Admin: new Set([devAdminRoleId, prodAdminRoleId]),
    Finance: new Set([devFinanceRoleId, prodFinanceRoleId]),
    HR: new Set([devHRRoleId, prodHRRoleId]),
    Manager: new Set([devManagerRoleId, prodManagerRoleId]),
    Supervisor: new Set([devSupervisorRoleId, prodSupervisorRoleId]),
    Officer: new Set([devOfficerRoleId, prodOfficerRoleId]),
}
export const roleIsOneOf = (roleId: string, roleNames: Role[]) =>
    roleNames.some((roleName) => roleIdsByName[roleName].has(roleId))
export const useUserIsReferenceRolePaySettingsPrivileged = (
    referenceRoleId: string | undefined
): boolean => {
    const userIsFinancePrivileged = useUserIsFinancePrivileged()
    const userRoles = useRolesOfUser()
    if (!userIsFinancePrivileged) {
        return false
    }
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return roleIsOverReferenceRoleId(maxRole, referenceRoleId)
}

export const useUserIsReferenceRoleEmployeeDocumentsPrivileged = (
    referenceRoleId: string | undefined
): boolean => {
    // Admin & HR roles can see everyone's documents
    // Other roles are only able to see documents of employee's below their own role
    const userRoles = useRolesOfUser()
    if (userRoles.includes(['Admin']) || userRoles.includes(['HR'])) {
        return true
    }
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return roleIsOverReferenceRoleId(maxRole, referenceRoleId)
}

export const useUserIsReferenceRolePayDataPrivileged = (
    referenceRoleId: string | undefined
): boolean => {
    const userRoles = useRolesOfUser()
    if (userRoles.includes(['Admin'])) {
        return true
    }
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return roleIsOverReferenceRoleId(maxRole, referenceRoleId)
}

export const nonSupervisorRoles = [
    ADMIN_ROLE,
    MANAGER_ROLE,
    HR_ROLE,
    FINANCE_ROLE,
]
export const payRateRoles = [ADMIN_ROLE, MANAGER_ROLE, HR_ROLE, FINANCE_ROLE]
export const legalInfoRoles = [ADMIN_ROLE, HR_ROLE, FINANCE_ROLE]
export const billRateRoles = [ADMIN_ROLE, FINANCE_ROLE]
