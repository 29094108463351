import { useLoadShiftInstances } from '../services/timecard_v2'

import { useLoadOfficers } from '../services/officer'

import { shiftInstanceTransform } from '../transformers/shiftInstanceTransformer'

import { useV4ScheduleFlag } from './useSchedule'

import { useLoadCustomersForSchedule } from 'src/services/schedule'

import {
    useLoadShiftInstancesForTKPeriod,
    useLoadShiftInstancesForTKDay,
} from '../services/timecard'
import { NewShiftInstance } from 'src/types/newShiftInstance'

type ScheduleResults = {
    shift_instances: NewShiftInstance[]
    isLoading: boolean
    isError: boolean
}
export function useTimekeepingForDay(
    startDate: string,
    endDate: string,
    targetDate: string
): ScheduleResults {
    const v4ScheduleFlag = useV4ScheduleFlag()

    const {
        shift_instances: newShiftInstances,
        isLoading: isv4ScheduleFlagLoading,
    } = useTimekeepingNew(
        startDate,
        endDate,
        undefined,
        v4ScheduleFlag,
        targetDate
    )

    const {
        shift_instances: oldShiftInstances,
        isLoading: isOldScheduleLoading,
    } = useLoadShiftInstancesForTKDay(
        targetDate,
        startDate,
        endDate,
        v4ScheduleFlag
    )
    const shift_instances = v4ScheduleFlag
        ? newShiftInstances
        : oldShiftInstances
    const isLoading = v4ScheduleFlag
        ? isv4ScheduleFlagLoading
        : isOldScheduleLoading

    return {
        shift_instances: shift_instances ?? [],
        isLoading: isLoading,
        isError: false,
    }
}

export function useTimekeeping(
    startDate: string,
    endDate: string,
    payGroupId: string | undefined
): ScheduleResults {
    const v4ScheduleFlag = useV4ScheduleFlag()

    const {
        shift_instances: newShiftInstances,
        isLoading: isv4ScheduleFlagLoading,
    } = useTimekeepingNew(startDate, endDate, payGroupId, v4ScheduleFlag)

    const {
        shift_instances: oldShiftInstances,
        isLoading: isOldScheduleLoading,
    } = useLoadShiftInstancesForTKPeriod(
        startDate,
        endDate,
        payGroupId,
        v4ScheduleFlag
    )

    const shift_instances = v4ScheduleFlag
        ? newShiftInstances
        : oldShiftInstances
    const isLoading = v4ScheduleFlag
        ? isv4ScheduleFlagLoading
        : isOldScheduleLoading

    return {
        shift_instances: shift_instances ?? [],
        isLoading: isLoading,
        isError: false,
    }
}

export function useTimekeepingNew(
    startDate: string,
    endDate: string,
    payGroupId: string | undefined,
    v4ScheduleFlag: boolean,
    targetDate?: string | undefined
): ScheduleResults {
    const {
        shiftInstances,
        isLoading: isScheduleLoading,
        isError: isScheduleError,
    } = useLoadShiftInstances(
        startDate,
        endDate,
        v4ScheduleFlag,
        payGroupId,
        targetDate
    )

    const {
        customers,
        isLoading: isCustomerLoading,
        isError: isCustomerError,
    } = useLoadCustomersForSchedule(startDate, endDate)

    const {
        officers,
        isLoading: isOfficersLoading,
        isError: isOfficersError,
    } = useLoadOfficers(/*onlyTerminated=*/ false, /*allOfficers=*/ true)

    if (isScheduleLoading || isOfficersLoading || isCustomerLoading) {
        return {
            shift_instances: [],
            isLoading: true,
            isError: false,
        }
    }

    if (isScheduleError || isOfficersError || isCustomerError) {
        return {
            shift_instances: [],
            isLoading: false,
            isError: true,
        }
    }

    return {
        shift_instances: shiftInstanceTransform(
            shiftInstances,
            officers,
            customers
        ),
        isLoading: isScheduleLoading,
        isError: isScheduleError,
    }
}
