import { useState } from 'react'
import { Customer, ActivityOfficer, ActivityType } from '../generated'

export const OfficerShiftStatuses = [
    'No Assigned Officer',
    'Not Clocked In',
    'Clocked Out Early',
    'Not On Site',
    'Incidents Reported',
    'On Break',
    'Past Clock-out Time',
    'All Clear',
    'Unknown Location',
    'No Recent Location Updates',
]

export const useBelltowerFilters = () => {
    const [selectedCustomersForFilter, setSelectedCustomersForFilter] =
        useState<Customer[]>([])
    const [selectedOfficersForFilter, setSelectedOfficersForFilter] = useState<
        ActivityOfficer[]
    >([])
    const [selectedStatusesForFilter, setSelectedStatusesForFilter] = useState<
        string[]
    >([])
    const [selectedActivityTypesForFilter, setSelectedActivityTypesForFilter] =
        useState<ActivityType[]>([])

    const filterShiftInstancesFunction = (shift_instance: any) => {
        const passesCustomer =
            selectedCustomersForFilter.some((customer: Customer) => {
                return shift_instance.customer_id === customer.id
            }) || selectedCustomersForFilter.length === 0
        const passesOfficer =
            selectedOfficersForFilter.some((officer: ActivityOfficer) => {
                return shift_instance.officer_id === officer.id
            }) || selectedOfficersForFilter.length === 0
        const passesStatuses =
            selectedStatusesForFilter.some((status: string) => {
                return shift_instance.officer_status === status
            }) || selectedStatusesForFilter.length === 0
        const passesActivityType =
            selectedActivityTypesForFilter.some((type: ActivityType) => {
                return (
                    shift_instance.activities?.length &&
                    shift_instance.activities[0].activity_type === type
                )
            }) || selectedActivityTypesForFilter.length === 0

        return (
            passesCustomer &&
            passesStatuses &&
            passesOfficer &&
            passesActivityType
        )
    }

    const filterCount =
        selectedCustomersForFilter.length +
        selectedOfficersForFilter.length +
        selectedStatusesForFilter.length +
        selectedActivityTypesForFilter.length

    return {
        selectedCustomersForFilter,
        setSelectedCustomersForFilter,
        selectedOfficersForFilter,
        setSelectedOfficersForFilter,
        selectedStatusesForFilter,
        setSelectedStatusesForFilter,
        selectedActivityTypesForFilter,
        setSelectedActivityTypesForFilter,
        filterShiftInstancesFunction,
        filterCount,
    }
}
