import { PartialRecord } from 'src/types/utilityTypes'

export const TEST_ORG_DEVO = '01411827-bfe8-4c41-ab44-ec594c4670a8'
export const TEST_ORG_PROD = '01411827-bfe8-4c41-ab44-ec594c4670a8'
export const DEMO_ONE_ORG = '87411c28-6965-4a25-bb59-9bd15958850c'
export const DEMO_TWO_ORG = '4e1e458e-155f-450b-aaae-2ebcf613f48e'

const _24_7_SECURITY_PROD = '2240e3ff-d2d0-4152-889a-2bb454de8d29'
const A_AND_A_PROD = '037cf4e7-3815-4c75-99de-1c15c7dc68ad'
const ACE_PRIVATE_SECURITY_PROD = '3fcbce15-ecfa-4a80-8515-3de467ea1633'
const ARUX_PROD = 'b4c2648d-f8ed-4659-ab88-409b71790958'
const CENTURION_PROD = '6f55992d-74a4-4ece-a13b-988906750e45'
const COMMAND_PROD = 'deb9c026-7a8d-4644-8730-6acef907ed21'
const COVEY_PROD = '4cc8308b-d375-4569-a6e5-423d563e617c'
const GREENLIGHT_PROD = '9729122e-cc3a-4c7f-9e0d-2c9fa81f6d1d'
const HOWE_PROD = '151e893e-218d-43ce-bcfe-6b2b1c77ed25'
const INDUSTRY_PROD = '88b054fa-461a-497c-a087-9194f50a50a6'
const JOHNSON_PROD = '12b30997-7b41-460c-a2c1-b32e5544fdf6'
const KRE_PROD = '0a7cff41-44cc-4df6-a44a-d78818fce83e'
const MERCHANTS_PROD = '49b85de6-97fc-4f4a-91da-e608e47d3401'
const MILLER_PROD = '3435c83c-ef62-4f60-b63d-ce4102cc0ed6'
const MPI_PROTECTIVE_PROD = '7fe2c175-cafb-459e-acad-b5f27cdf8d5d'
const NAIAS_PROD = '9f5c6b0d-2d6f-44a0-bd61-7cf08c07f51c'
const NEXT_LEVEL_PROD = '56ae1e15-76af-4f9b-8786-805a9440d9c2'
const NOLA_PROTECTION_PROD = '0c1f5a08-d7e2-4019-a94c-b26b1f1d43a8'
const N_TACT_PROD = '01cee0f0-d2a1-427d-bf44-0bffe25a314e'
const OMNIUM_PROD = '7d292ee3-194e-4648-8bed-fff67108d179'
const OVERWATCH_PROD = '350b0536-a659-4f8c-8c4c-6b31f804b97c'
const PHOENIX_PROD = 'a3ba24d7-14ad-4386-b76e-381a87366336'
const PM_SECURITY_PROD = 'd6c80629-1cb8-4a50-bf8d-43caabd6b534'
const PRICE_PROTECTIVE_PROD = '1c2708bf-e8ba-4cb8-8425-b44393f324a4'
const PRO_SECURITY_PROD = 'f32a0b56-e8ed-4420-8376-677df129bdc9'
const PROFESSIONAL_PROTECTION_PROD = '5201b7f2-ae93-4601-b46b-72d8344bbb0d'
const SALIENT_DEFENSE_PROD = 'd70be0e8-2e9b-493b-9f26-1ee30d6ce69c'
const SALIENT_OPERATIONS_PROD = 'e15341c7-23be-4253-8ca3-f7b722422273'
const SHEEPDOG_PROD = 'd708f312-734a-4a78-9e8d-d4f77f24587d'
const SMR_PROD = '456fea8e-7902-4e17-b80d-1b58c5a024f8'
const STARK_SECURITY_PROD = '49801258-bd3e-4aca-99d5-790d03317e75'
const STATEWIDE_PROD = '57dd316f-c1f4-49ea-9497-6bfc662339cd'
const SUN_CITY_PROD = '6f12a585-dff4-4b3e-8878-b2904eb437db'
const TASK_FORCE_PROD = '685d87bc-b155-40d4-9203-83fff42bdf26'
const TRESTON_PROD = '4793f28e-8290-4bf7-b1f1-73f3644cdaae'
const TRITON_PROD = 'ffedfc43-162b-48da-b116-1efdd321b1c0'
const SHAW_MANAGEMENT_PROD = '6f2121a9-a4fb-4a97-ae17-0b8c4d28a5d2'
const CONVOY_GROUP_PROD = '87cfc320-d8df-4bbf-bfdc-8394983504a1'
const SECURITY_PRO_INTEL_PROD = '6eba008a-257c-4949-9b04-7ad9b7fe6e5b'
const ETERNAL_SECURITY_SERVICES_PROD = 'c61bb625-4ab5-4325-97de-2130401614a3'
const FORTIS_SECURITY_PROD = '0979cdc4-eec7-4993-ae8e-7a8127d7e10f'

const BELFRY_ORGS: string[] = [TEST_ORG_DEVO, TEST_ORG_PROD]
const PAYROLL_V2_ORGS: string[] = [DEMO_ONE_ORG]

const FRIENDLY_ORGS: string[] = [
    COMMAND_PROD,
    COVEY_PROD,
    CENTURION_PROD,
    MPI_PROTECTIVE_PROD,
    NEXT_LEVEL_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    SMR_PROD,
]

const DEMO_ORGS: string[] = [DEMO_ONE_ORG, DEMO_TWO_ORG]

export enum Features {
    WOTC = 'WOTC',
    PAY_GROUP_FACILITATORS = 'PAY_GROUP_FACILITATORS',
    MASS_ANNOUNCEMENTS = 'MASS_ANNOUNCEMENTS',
    AD_HOC_INV_START_BYPASS = 'AD_HOC_INV_START_BYPASS',
    TIME_OFF_SPBS = 'TIME_OFF_SPBS',
    TIMEKEEPING_V2 = 'TIMEKEEPING_V2',
    WAGE_BASED_TIMEKEEPING = 'WAGE_BASED_TIMEKEEPING',
    CUSTOM_INCIDENT_TYPES = 'CUSTOM_INCIDENT_TYPES',
    PAYROLL_V2 = 'PAYROLL_V2',
    PAYROLL_V2_SIDE_BY_SIDE = 'PAYROLL_V2_SIDE_BY_SIDE',
    CONTRACTOR_DEDUCTIONS = 'CONTRACTOR_DEDUCTIONS',
    ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
    EMAIL_NOTIFICATIONS = 'EMAIL_NOTIFICATIONS',
    INVENTORY = 'INVENTORY',
    DISPATCH = 'DISPATCH',
    // TODO(rdylan): Remove after soak time of ~2 weeks (2024-07-14)
    POSTORDER_API_V2 = 'POSTORDER_API_V2',
    MFA = 'MFA',
}
export type FeatureGate = PartialRecord<Features, string[]>
export const FEATURE_GATE = {
    [Features.WOTC]: [...BELFRY_ORGS, STARK_SECURITY_PROD, ...DEMO_ORGS],
    [Features.PAY_GROUP_FACILITATORS]: [
        ...BELFRY_ORGS,
        NOLA_PROTECTION_PROD,
        OVERWATCH_PROD,
        SECURITY_PRO_INTEL_PROD,
        ...DEMO_ORGS,
    ],
    [Features.MASS_ANNOUNCEMENTS]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.AD_HOC_INV_START_BYPASS]: [
        ...BELFRY_ORGS,
        SUN_CITY_PROD,
        A_AND_A_PROD,
        ...DEMO_ORGS,
    ],
    [Features.TIME_OFF_SPBS]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.TIMEKEEPING_V2]: [...BELFRY_ORGS, ...DEMO_ORGS] as string[],
    [Features.WAGE_BASED_TIMEKEEPING]: [...BELFRY_ORGS] as string[],
    [Features.CUSTOM_INCIDENT_TYPES]: [
        ...BELFRY_ORGS,
        GREENLIGHT_PROD,
        OVERWATCH_PROD,
        FORTIS_SECURITY_PROD,
        ...DEMO_ORGS,
    ] as string[],
    // Organization in this flag get full Payroll V2 experience without any
    // references to V1.
    [Features.PAYROLL_V2]: [...PAYROLL_V2_ORGS] as string[],
    // Similar to SHADOW option, with once exception that client is
    // able to see both version of payroll. This is meant for TEST orgs only,
    // to be able to compare payrolls side-by-side on the screen. PLEASE
    // YELL IF ANY ORG OTHER THAN TEST FIRMS IS ADDED!!!!!!!!
    [Features.PAYROLL_V2_SIDE_BY_SIDE]: [...PAYROLL_V2_ORGS] as string[],
    [Features.CONTRACTOR_DEDUCTIONS]: [...PAYROLL_V2_ORGS] as string[],
    [Features.ACCOUNTS_PAYABLE]: [...BELFRY_ORGS, ...DEMO_ORGS] as string[],
    [Features.INVENTORY]: [...BELFRY_ORGS, ...DEMO_ORGS] as string[],
    [Features.EMAIL_NOTIFICATIONS]: [
        ...BELFRY_ORGS,
        ...FRIENDLY_ORGS,
        KRE_PROD,
        JOHNSON_PROD,
        ...DEMO_ORGS,
    ] as string[],
    [Features.DISPATCH]: [...BELFRY_ORGS, ...DEMO_ORGS] as string[],
    [Features.POSTORDER_API_V2]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.MFA]: [...BELFRY_ORGS, ...DEMO_ORGS] as string[],
}

export const FEATURED_ROLLOUT_TO_ALL_FIRMS: PartialRecord<Features, boolean> =
    {}

export const INTERNAL_USERS = [
    // 'auth0|62cb212df2c735713aef0f35', // Alex
    'auth0|62eab5b3216efec017dea581', // hello@
    'auth0|6532aed6e475da095b945e65', // hello1@
    'auth0|6573a359bf8a286ca6ff8b3d', // hello2@
    'auth0|65b41d4375425212b4ea1597', // hello3@
    'auth0|661554a7aa70f7f377dc7905', // hello4
    'auth0|669a9029ceb2563e0da12754', // hello5
    'auth0|66db5ab675b7a20957387178', // hello6
]

export const INTERNAL_FIRMS = [
    '01411827-bfe8-4c41-ab44-ec594c4670a8', // BelfryTest
    'a0d9a84e-2c83-4450-8e06-a399f5716099', // Dev
    '87411c28-6965-4a25-bb59-9bd15958850c', // Demo One
    '4e1e458e-155f-450b-aaae-2ebcf613f48e', // Demo Two
]
